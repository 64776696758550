import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { Panel } from "../../../../components/Panel";
import moment from "moment";
import { ColorChip } from "../../../../components/ColorChip";
import { FilterSelect } from "./components/FilterSelect";
import {
  commaSeparatedNumber,
  formatNumber,
  getUser,
} from "../../../../helpers/utils";
import { api } from "../../../../helpers/api";
import Spinner from "../../../../components/Spinners";
import useResponsive from "../../../../helpers/useResponsive";

const lastTypes = [
  { label: "1 day", value: "1_day" },
  { label: "7 days", value: "7_days" },
  { label: "30 days", value: "30_days" },
];
const nextTypes = [
  { label: "7 days", value: "7_days" },
  { label: "30 days", value: "30_days" },
  { label: "3 months", value: "90_days" },
  { label: "All time", value: "all_time" },
];

const calculateHeight = () => {
  // screen height is 1000px then height should be 250px
  // screen height is 1200px then height should be 300px
  // screen height is 1400px then height should be 350px
  const screenHeight = window.innerHeight;
  return Math.round(screenHeight * 0.2);
};

const getDates = (type: string) => {
  if (type === "7_days") {
    return {
      startDate: moment().subtract(7, "days").format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      type,
    };
  } else if (type === "30_days") {
    return {
      startDate: moment().subtract(30, "days").format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      type,
    };
  } else if (type === "1_day") {
    return {
      startDate: moment().subtract(1, "days").format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      type,
    };
  }
  return {};
};

const getTargetDates = (type: string) => {
  if (type === "7_days") {
    return {
      targetStartDate: moment().format("YYYY-MM-DD"),
      targetEndDate: moment().add(7, "days").format("YYYY-MM-DD"),
      type,
    };
  } else if (type === "30_days") {
    return {
      targetStartDate: moment().format("YYYY-MM-DD"),
      targetEndDate: moment().add(30, "days").format("YYYY-MM-DD"),
      type,
    };
  } else if (type === "90_days") {
    return {
      targetStartDate: moment().format("YYYY-MM-DD"),
      targetEndDate: moment().add(90, "days").format("YYYY-MM-DD"),
      type,
    };
  } else if (type === "all_time") {
    return {
      targetStartDate: "",
      targetEndDate: "",
      type,
    };
  }
};

const sortData = (data: any) => {
  return data.sort((a: any, b: any) => {
    return a.date ? moment(a.date).diff(moment(b.date ? b.date : b.label)) : moment(a.label).diff(moment(b.date ? b.date : b.label));
  });
};

const sanitizeChartData = (data: any[]) => {
  return data.map((item) => ({
    ...item,
    date: item.date || item.label,
    sum: Number(item.sum) || 0,
  }));
};

const CustomCursor = (props) => {
  const { x, y, width, height, stroke } = props;
  // make width 25px
  const newWidth = 25;
  // set x in center
  const remaining = (width - (newWidth + 1)) / 2;
  return (
    <Rectangle
      fill="url(#gradient)"
      x={x + remaining}
      y={y - 5}
      width={newWidth}
      height={height + 5}
      radius={[6, 6, 0, 0]}
    />
  );
};

const CustomTooltip = ({ active, payload, label }: any) => {
  return (
    <div className="bg-white rounded-[8px] shadow-[0px_8px_28px_0px_rgba(20,20,43,0.10)] border border-[#F0F0F0]">
      <div className="text-xs text-muted-foreground mb-3 text-center bg-[#F4F4F4] p-2">
        {moment(label).format("MMM DD, YYYY") === "Invalid date" ? label : moment(label).format("MMM DD, YYYY")}
      </div>
      <div className="space-y-2 pt-2 pb-4 pl-4 pr-4">
        {/* <span className="!text-[12px]">$</span> */}
        <span className="!text-[12px]">{commaSeparatedNumber(payload[0]?.value, false)}</span>
      </div>
    </div>
  );
};

export function PickupReportChart() {
  const containerRef = useRef<HTMLDivElement>(null);
  // useImperativeHandle(ref, () => containerRef.current);

  const [sizes, setSizes] = useState<{
    containerWidth: number;
    containerHeight: number;
  }>({
    containerWidth: -1,
    containerHeight: -1,
  });

  const setContainerSize = useCallback(
    (newWidth: number, newHeight: number) => {
      setSizes((prevState) => {
        const roundedWidth = Math.round(newWidth);
        const roundedHeight = Math.round(newHeight);
        if (
          (prevState.containerWidth === roundedWidth &&
            prevState.containerHeight === roundedHeight) ||
          (prevState.containerHeight > 0 && roundedHeight === 0)
        ) {
          return prevState;
        }
        return {
          containerWidth: roundedWidth,
          containerHeight: roundedHeight,
        };
      });
    },
    []
  );

  const [isLoading, setIsLoading] = useState(false);
  const [calHeight, setCalHeight] = useState(0);
  const user = getUser();
  const { isMobile, isTall3XLDesktop, isQHD, isQHD2 } = useResponsive();
  const [data, setData] = useState<any>({});
  const [filter, setFilter] = useState<any>({
    lastType: "7_days",
    nextType: "7_days",
    ...getDates("7_days"),
    ...getTargetDates("7_days"),
  });
  const fetchMetrics = async () => {
    try {
      setIsLoading(true);
      const userId = user?.id;
      const filters = {
        // startDate: "2024-08-12",
        ...getDates(filter.lastType || "7_days"),
        ...getTargetDates(filter.nextType || "7_days"),
        type: "PickUp",
      };
      if (filters.startDate && filters.endDate) {
        const metrics = await api.getMetrics(userId, filters);
        setData(metrics);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    fetchMetrics();
  }, [filter]);


  const chartData = sanitizeChartData(sortData(data?.pickUpReportByDates || []));
  console.log("chartData", chartData);
  const handleResize = () => {
    const { width: containerWidth = 0, height: containerHeight = 0 } =
      containerRef?.current?.getBoundingClientRect() || {};
    setContainerSize(containerWidth, containerHeight);
    const cal = document.getElementById("calendar-view");
    if (cal) {
      console.log(cal.getBoundingClientRect());
      setCalHeight(cal.getBoundingClientRect().height);
    }
  };

  useEffect(() => {
    const { width: containerWidth = 0, height: containerHeight = 0 } =
      containerRef?.current?.getBoundingClientRect() || {};
    setContainerSize(containerWidth, containerHeight);
  }, [calHeight]);

  useEffect(() => {
    // implement resize event listner
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    let callback = (entries: ResizeObserverEntry[]) => {
      const { width: containerWidth, height: containerHeight } =
        entries[0].contentRect;
      setContainerSize(containerWidth, containerHeight);
      // onResizeRef.current?.(containerWidth, containerHeight);
    };
    const observer = new ResizeObserver(callback);

    const { width: containerWidth = 0, height: containerHeight = 0 } =
      containerRef?.current?.getBoundingClientRect() || {};
    setContainerSize(containerWidth, containerHeight);
    if (containerRef?.current) {
      observer.observe(containerRef?.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  const width =
    chartData.length * 35 < 400 ? (isQHD ? 600 : 500) : chartData.length * 35;

  const maxSum = Math.max(
    0,
    ...chartData.map((item) => Number(item.sum || 0))
  ).toFixed(0);
  return (
    <Panel
      label="Pick-Up Report"
      className="pickup-report-chart h-full overflow-y-hidden min-w-[230px] tall3XL:[&_.header]:h-[50px]"
      contentClassName="h-[calc(100%-50px)] overflow-y-hidden"
      style={{
        ...(calHeight > 0 && {
          height: `${calHeight}px`,
        }),
      }}
      infoTooltip="This chart displays the revenue generated from room bookings during the selected time period. It offers insights into the pace of bookings, helping you understand how quickly or slowly reservations are being made."
    >
      {isLoading ? (
        <div className="flex justify-center items-center h-[350px]">
          <Spinner className="!text-primary" />
        </div>
      ) : (
        <div className="h-full">
          {/* <div className="px-2 py-2.5 bg-[rgb(216 230 224)] text-center text-[rgb(216 230 224)] text-3xs font-medium border-b justify-center items-center flex">
            At this rate, you are expected to hit your budget for Feb
          </div> */}
          <div className="p-3 gap-1 items-center flex flex-wrap text-2xs text-[#000] font-medium tracking-[-0.11px]">
            <span>In the last</span>
            <FilterSelect
              filter={filter}
              setFilter={setFilter}
              types={lastTypes}
              fieldName="lastType"
            />
            <span>you've</span>
            <span>picked</span>
            <span>up</span>
            <span>
              {commaSeparatedNumber(data?.totalRevenue || 0, false)}, for the
              next
            </span>
            <FilterSelect
              filter={filter}
              setFilter={setFilter}
              types={nextTypes}
              fieldName="nextType"
            />
          </div>

          <div
            className="relative overflow-auto !overflow-y-hidden min-w-[218px] pl-4 h-[calc(100%-170px)] 2k:h-[calc(100%-150px)] flex justify-start items-center"
            ref={containerRef}
          >
            {data?.pickUpReportByDates?.length === 0 && (
              <div className="absolute top-0 text-[#000] text-sm font-medium left-0 flex justify-center text-center flex-1 items-center w-full h-full">
                No data available
              </div>
            )}
            <div
              className={`graph-wrapper w-full lg:max-w-[22vw] tall3XL:max-w-[20vw] 2k:max-w-[24vw]`}
              onScroll={(e) => {
                let ele = document.getElementsByClassName("recharts-yAxis")[1];
                if (ele && ele instanceof HTMLElement && ele.style) {
                  ele.style.transform = `translateX(${(e.target as HTMLElement).scrollLeft
                    }px)`;
                }
              }}
            >
              <BarChart
                width={sizes.containerWidth - sizes.containerWidth * 0.15}
                height={sizes.containerHeight}
                data={chartData}
                margin={{
                  top: 5,
                  right: 10,
                  left: 0,
                  bottom: 2,
                }}
                barGap={4}
              >
                <CartesianGrid
                  strokeDasharray="0"
                  vertical={false}
                  horizontal={{
                    color: "red",
                  }}
                />
                <XAxis
                  dataKey="date"
                  axisLine={false}
                  tickLine={false}
                  className="!text-3xs !text-neutral-600 leading-[11px]"
                  angle={["all_time", "90_days"].includes(filter.nextType) ? -45 : filter.nextType ===
                    "30_days" ? -70 : -45}
                  tickFormatter={(value) => {
                    if (["all_time", "90_days"].includes(filter.nextType)) {
                      return moment(value, "MMMM YYYY").format("MMM YYYY");
                    }
                    if (["30_days"].includes(filter.nextType)) {
                      return moment(value).format("M/DD");
                    }
                    return moment(value).format("ddd");
                  }}
                  interval={0}

                />
                <YAxis
                  axisLine={false}
                  tickLine={false}
                  width={12 + maxSum?.toString()?.length * 9}
                  className="!text-2xs !text-neutral-600 leading-[11px]"
                  tickFormatter={(value) => {
                    // make value to k denotes thousands
                    const val = Number(value);
                    // If zero then show 0 without dollar sign
                    if (val === 0) {
                      return "0";
                    }
                    if (val > 1000) {
                      return `${formatNumber(Number(val || 0), "$")}`;
                    } else {
                      return `$${val}`;
                    }
                  }}
                />
                <Tooltip
                  position={{ y: 0 }}
                  content={<CustomTooltip />}
                  cursor={
                    data?.pickUpReportByDates?.length > 0 && <CustomCursor />
                  }
                />
                <defs>
                  <linearGradient
                    id="gradient"
                    x1="0%"
                    y1="0%"
                    x2="0%"
                    y2="100%"
                  >
                    <stop offset="0%" stop-color="rgb(216 230 224)" />
                    <stop offset="100%" stop-color="white" />
                  </linearGradient>
                </defs>
                <Bar
                  dataKey="sum"
                  fill="#000"
                  barSize={
                    ["all_time", "30_days"].includes(filter.nextType) ? 12 : 18
                  }
                  radius={[2, 2, 0, 0]}
                />
              </BarChart>
            </div>
          </div>
          <div className="border-t border-t-neutral-300 px-[15px] py-3 flex flex-col gap-1.5">
            <div className="flex gap-1 items-center justify-between">
              <div className="text-2xs text-neutral-600 font-medium">
                Rooms Sold:
              </div>
              <div className="flex gap-3 items-center">
                <span className="text-2xs text-neutral-600 font-medium">
                  {commaSeparatedNumber(data?.totalBookings || 0, false, false)}
                </span>
                <ColorChip
                  text={`${commaSeparatedNumber(
                    data?.totalBookingsPercentage || 0,
                    true
                  )}`}
                  type={
                    data?.totalBookingsPercentage === 0
                      ? "grey"
                      : "rgb(216 230 224)"
                  }
                  className="min-w-[40px]"
                />
              </div>
            </div>
            <div className="flex gap-1 items-center justify-between">
              <div className="text-2xs text-neutral-600 font-medium">ADR:</div>
              <div className="flex gap-3 items-center">
                <span className="text-2xs text-neutral-600 font-medium">
                  {commaSeparatedNumber(data?.adr, false)}
                </span>
                <ColorChip
                  text={`${commaSeparatedNumber(
                    data?.adrPercentage || 0,
                    true
                  )}`}
                  type={
                    data?.adrPercentage === 0
                      ? "grey"
                      : "rgb(216 230 224)"
                  }
                  className="min-w-[40px]"
                />
              </div>
            </div>
            <div className="flex gap-1 items-center justify-between">
              <div className="text-2xs text-neutral-600 font-medium">
                Revenue:
              </div>
              <div className="flex gap-3 items-center">
                <span className="text-2xs text-neutral-600 font-medium">
                  {commaSeparatedNumber(data?.totalRevenue || 0, false)}
                </span>
                <ColorChip
                  text={`${commaSeparatedNumber(
                    data?.totalRevenuePercenatge || 0,
                    true
                  )}`}
                  type={
                    data?.totalRevenuePercenatge === 0
                      ? "grey"
                      : "rgb(216 230 224)"
                  }
                  className="min-w-[40px]"
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </Panel>
  );
}