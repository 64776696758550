import { useRef } from "react";
import { ReactComponent as LeftChevronIcon } from "../assets/icons/left-arrow.svg";
import { ReactComponent as InfoIcon } from "../assets/icons/info.svg";

export function Panel({
  actions,
  children,
  label,
  className = "",
  contentClassName = "",
  showHeader = true,
  showNavigation = false,
  id,
  style,
  infoTooltip,
}: {
  actions?: React.ReactNode;
  children: React.ReactNode;
  label: string;
  className?: string;
  contentClassName?: string;
  showHeader?: boolean;
  showNavigation?: boolean;
  id?: string;
  style?: React.CSSProperties;
  infoTooltip?: string;
}) {
  const tableRef = useRef<HTMLTableElement>(null);

  return (
    <div
      id={id}
      className={`rounded-[8px] relative w-[100%] border-[1px] border-[#E7E5E4] overflow-visible bg-white [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none] h-full ${className}`}
      onClick={(e) => e.stopPropagation()}
      style={{ ...style }}
    >
      {showHeader && (
        <div className="header pl-4 !min-h-[56px] px-4 !py-3 flex justify-between items-center align-center gap-2 border-b border-[#E7E5E4]">
          <div className="flex items-center gap-2">
            <div className="text-black text-[14px] lg:text-[16px] tall:lg:text-[18px] tall:xl:text-[20px] font-semibold">{label}</div>
            {infoTooltip && (
              <div title={infoTooltip}>
                <InfoIcon className="w-4 h-4 text-neutral-500" />
              </div>
            )}
          </div>
          {actions}
        </div>
      )}
      <div ref={tableRef} className={`overflow-visible [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none] ${contentClassName}`}>
        {children}

        {showNavigation && (
          <div
            className={`flex md:hidden absolute bottom-3 left-0 right-0 items-center gap-2 justify-center`}
          >
            <button
              type="button"
              onClick={() => tableRef.current?.scrollBy(-300, 0)}
              title={"left"}
              className="w-6 h-6 shadow-[0px_1px_2px_0px_rgba(16,24,40,0.06)] flex justify-center items-center rounded border border-[#E7E5E4] bg-[#F7F7FC]"
            >
              <LeftChevronIcon className="w-4 h-4" />
            </button>
            <button
              type="button"
              onClick={() => tableRef.current?.scrollBy(300, 0)}
              title={"right"}
              className="w-6 h-6 shadow-[0px_1px_2px_0px_rgba(16,24,40,0.06)] flex justify-center items-center rounded border border-[#E7E5E4] bg-[#F7F7FC]"
            >
              <LeftChevronIcon className="w-4 h-4 rotate-180" />
            </button>
          </div>
        )}
      </div>
    </div>
  );
}