import Button from "../../../components/Button";
import { Card } from "../../../components/Card";
import { ColorChip } from "../../../components/ColorChip";
import { Panel } from "../../../components/Panel";
import { ReactComponent as RightArrow } from "../../../assets/icons/right-arrow.svg";
import { PriceExplanation } from "./PriceExplanation";
import { useState, useEffect, useRef } from "react";
import moment from "moment";
import { commaSeparatedNumber } from "../../../helpers/utils";
import Spinner from "../../../components/Spinners";
import { ReactComponent as InfoIcon } from "../../../assets/icons/info.svg";
export function Summary({
  selectedDate,
  onOpenPriceAdjustmentModal,
  data,
  isLoading,
}: {
  selectedDate?: string;
  onOpenPriceAdjustmentModal: () => void;
  data: any;
  isLoading: boolean;
}) {
  const [showPriceExplanation, setShowPriceExplanation] = useState(false);

  const price = data?.baseRoomTypeRate || 0;
  const avgPrice = (data?.competitorPrices || [])?.reduce(
    (total, curr) => total + Number(curr?.rate || 0),
    0
  ) || price;
  const percentage = (price / avgPrice) * 100;

  const handlePriceAdjustmentClick = () => {
    try {
      if (typeof onOpenPriceAdjustmentModal === 'function') {
        onOpenPriceAdjustmentModal();
      }
    } catch (error) {
      console.error('Error opening price adjustment modal:', error);
    }
  };

  return (
    <div className="bg-[#F9F6F6] border-lg rounded-[8px] border-[#E5E7EB]">
      <div className="mx-auto min-w-[440px] p-4 space-y-8 flex-start">
        <div className="flex justify-between items-center border-b border-[#000] pb-4">
          <h1 className="text-[16px] lg:text-[18px] tall:lg:text-[20px] tall:xl:text-[20px] font-semibold text-black">Daily Summary</h1>
          <span className="text-[13px]">{moment(selectedDate).format("MMM D, YYYY")}</span>
        </div>

        {isLoading ? (
          <div className="h-[calc(100dvh-150px)] flex justify-center items-center">
            <Spinner className="!text-primary" />
          </div>
        ) : (
          <>
            <section>
              <h2 className="text-[14px] font-semibold mb-4">Performance Summary</h2>
              <div className="bg-white rounded-[4px] px-2 ">
                <div className="divide-y">
                  <div className="flex justify-between items-center p-3">
                    <span className="text-[13px]">Days Before Arrival</span>
                    <span className="text-[13px]">{commaSeparatedNumber(data?.avgLengthArrival, false, false)}</span>
                  </div>
                  <div className="flex justify-between items-center p-3">
                    <span className="text-[13px]">Rooms Sold</span>
                    <span className="text-[13px]">{commaSeparatedNumber(data?.actualRoomsSold, false, false)}</span>
                  </div>
                  <div className="flex justify-between items-center p-3 ">
                    <span className="text-[13px]">Rooms Offline</span>
                    <span className="text-[13px]">{commaSeparatedNumber(data?.unavailableRooms, false, false)}</span>
                  </div>
                  <div className="flex justify-between items-center p-3 border-b border-[#000]">
                    <span className="text-[13px]">Occupancy</span>
                    <span className="text-[13px]">{commaSeparatedNumber(data?.totalOccupancy || 0, true, false, 0, 0)}</span>
                  </div>
                  <div className="flex justify-between items-center p-3">
                    <span className="text-[13px]">ADR</span>
                    <span className="text-[13px]">{commaSeparatedNumber(data?.adr || 0, false)}</span>
                  </div>
                  <div className="flex justify-between items-center p-3">
                    <span className="text-[13px]">RevPAR</span>
                    <span className="text-[13px]">{commaSeparatedNumber(data?.actualRevpar || 0, false)}</span>
                  </div>
                </div>
              </div>
            </section>

            <section>
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-[14px] font-semibold flex items-center gap-1">
                  Competitive Pricing
                  <div title={"The prices displayed are sourced from various online platforms and reflect the lowest publicly available room rates for the selected dates at each competing hotel."}>
                    <InfoIcon className="w-4 h-4 text-neutral-500" />
                  </div>
                </h2>
                <span className="text-[13px]">Standard Room</span>
              </div>
              <div className="bg-white rounded-[4px] px-2">
                <div className="divide-y">
                  {[
                    {
                      hotelName: "My Base Room Price",
                      rate: price,
                      varRatePercentage: 0,
                    },
                    ...(data?.competitorPrices || []),
                  ]?.map((competitor, index) => (
                    <div 
                      key={competitor.hotelName} 
                      className={`flex justify-between items-center p-4 ${
                        index === 0 ? 'font-semibold border-b border-black' : ''
                      }`}
                    >
                      <span className="text-[13px]">{competitor.hotelName}</span>
                      <div className="flex items-center gap-2">
                        {competitor.varRatePercentage !== 0 && (
                          <span className="text-[13px] text-gray-500">
                            {commaSeparatedNumber(competitor.varRatePercentage, true)}
                          </span>
                        )}
                        <span className="text-[13px]">{commaSeparatedNumber(competitor.rate, false)}</span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>

            <Button 
              className="group w-full bg-[#0B252A] text-white hover:bg-[#0B252A]/90 rounded-lg p-2 max-h-[40px] text-[13px] font-normal"
              onClick={handlePriceAdjustmentClick}
            >
              Adjust Prices
              <RightArrow className="ml-2 h-5 w-5 [&_path]:stroke-white group-hover:[&_path]:stroke-black" />
            </Button>
          </>
        )}
      </div>
    </div>
  );
}