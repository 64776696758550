import { SideModal } from "../../../components/SideModal";
import { ReactComponent as CloseIcon } from "../../../assets/icons/x.svg";
import Button from "../../../components/Button";
import { PriceInput } from "../../../components/PriceInput";
import { useState } from "react";
import { commaSeparatedNumber, getDate, getUser } from "../../../helpers/utils";
import { Switch } from "../../../components/Switch";
import moment from "moment";
import { api } from "../../../helpers/api";
import { ConfirmResetModal } from "./ConfirmResetModal";
import DatePicker from "react-datepicker";
import { ReactComponent as InfoIcon } from "../../../assets/icons/info.svg";

const calculateNewPrice = (
  newPriceOrPercentage: string,
  basePrice: string | number,
  relativePrice: boolean
) => {
  // check if newPriceOrPercentage is a number & base price is a number
  const newPriceOrPercentageNumber = Number(newPriceOrPercentage);
  const basePriceNumber = Number(basePrice);

  if (isNaN(newPriceOrPercentageNumber) || isNaN(basePriceNumber)) {
    return "";
  }
  if (relativePrice) {
    // it's a percentage
    const newPrice = (basePriceNumber * newPriceOrPercentageNumber) / 100;
    return (newPrice + basePriceNumber).toFixed(0);
  } else {
    // it's a new price
    return (newPriceOrPercentageNumber + basePriceNumber).toFixed(0);
  }
};

export function PriceAdjustmentModal({
  onClose,
  selectedDate,
  data,
  onUpdate,
}: {
  onClose: () => void;
  selectedDate?: string;
  data: any;
  onUpdate: () => void;
}) {
  const user = getUser();
  const [saving, setSaving] = useState(false);
  // const [dynamicRates, setDynamicRates] = useState({
  //   dynamicRate: api.fetchDynamicRates(user?.id, {
  //     date: moment(selectedDate).format("YYYY-MM-DD"),
  //   }),
  // });
  //const [newData, setNewData] = useState();
  const [firstLoad, setFirstLoad] = useState(true);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [form, setForm] = useState({
    ...(data || {}),
    relativePrice: true,
    bulk: false,
    rateFloor: data?.rateFloor,
    rateCeiling: data?.rateCeiling,
    baseRoomTypeNewPrice: "",
    startDate: moment(selectedDate).format("YYYY-MM-DD"),
    endDate: moment(selectedDate).endOf("month").format("YYYY-MM-DD"),
    roomTypes: data?.roomTypes?.map((room) => ({
      ...room,
      newPrice: "0",
      newFixedPrice: "",
    })),
  });
  const handleClose = () => {
    onClose();
  };

  const getNewData = async () => {
    
    const dayMetrics = await api.getDayMetrics(user?.id, {
      type: "Summary",
      date: moment(selectedDate).format("YYYY-MM-DD"),
    });
    console.log(dayMetrics, "DAY METRICS");
    return dayMetrics;
  };
  const handleResetAll = () => {
    setForm((prev) => ({
      ...prev,
      relativePrice: true,
      bulk: false,
      baseRoomTypeNewPrice: `${data?.baseRoomTypeRate || ""}`,
      roomTypes: data?.roomTypes?.map((room) => ({
        ...room,
        newPrice: `${(
          Number(data?.baseRoomTypeRate || 0) +
          (Number(room?.percentage) / 100) * Number(data?.baseRoomTypeRate || 0)
        ).toFixed(0)}`,
      
      })),
    }));
  };

  const handleReset = async () => {
    const isWeekday = moment(selectedDate).isoWeekday() === 6 || moment(selectedDate).isoWeekday() === 7;
    const floorCeiling = await api.getFloorCeiling(user?.id, {
      date: moment(selectedDate).format("YYYY-MM-DD"),
    });
    const data = await api.getDayMetrics(user?.id, {
      type: "Summary",
      date: moment(selectedDate).format("YYYY-MM-DD"),
    });
    const keepFloorCeiling = floorCeiling?.data ? !floorCeiling.data.isOverride : false;
    console.log("floorCeiling", floorCeiling);
    setForm((prev) => ({
      ...prev,
      baseRoomTypeRate: data?.baseRoomTypeRate,
      relativePrice: true,
      bulk: false,
      rateFloor: keepFloorCeiling ? floorCeiling?.data.rateFloor : "",
      rateCeiling: keepFloorCeiling ? floorCeiling?.data.rateCeiling : "",
      baseRoomTypeNewPrice: "",
      startDate: moment(selectedDate).format("YYYY-MM-DD"),
      endDate: moment(selectedDate).endOf("month").format("YYYY-MM-DD"),
      roomTypes: data?.roomTypes?.map((room) => ({
        ...room,
        newPrice: "0",
        fixed: room.fixed,
        percentage: room.percentage,
        newFixedPrice: "",
      })),
    }));
   
  };
  const handleSave = async () => {
    try {
      setFirstLoad(false);
      console.log("Full Data SAVE", form, data);
      setSaving(true);
      console.log("Full Data", form);
      if (form.baseRoomTypeNewPrice === "") {
        form.baseRoomTypeNewPrice = data?.baseRoomTypeRate;
      }
      const savedbase = form.baseRoomTypeNewPrice;
      // if (savedbase === "") {
      //   form.baseRoomTypeNewPrice = data?.baseRoomTypeRate;
      // }
      //console.log("FORM NEW PRICE Full Data", form.baseRoomTypeNewPrice, data?.baseRoomTypeRate, form.roomTypes);

      var floorCeiling = {};
      if (form?.bulk) {
        floorCeiling = {
          startDate: form?.startDate,
          endDate: form?.endDate,
          rateFloor: form?.rateFloor === "" ? null : Number(form?.rateFloor),
          rateCeiling:
            form?.rateCeiling === "" ? null : Number(form?.rateCeiling),
        };
        //console.log("Floor Ceiling DATA HERE", floorCeiling);
        await api.overrideBulkFloorCeiling(user?.id, floorCeiling);
        await api.syncRatesGrouped(user?.id, {
          startDate: moment(form?.startDate).format("YYYY-MM-DD"),
          endDate: moment(form?.endDate).format("YYYY-MM-DD"),
        });
      } else {
        floorCeiling = {
          date: form?.date,
          rateFloor: form?.rateFloor === "" ? null : Number(form?.rateFloor),
          rateCeiling:
            form?.rateCeiling === "" ? null : Number(form?.rateCeiling),
        };
        //console.log("Floor Ceiling DATA HERE", floorCeiling);
        await api.overrideFloorCeiling(user?.id, floorCeiling);
        await api.syncRatesGrouped(user?.id, {
          startDate: moment(form?.date).format("YYYY-MM-DD"),
          endDate: moment(form?.date).format("YYYY-MM-DD"),
        });
      }

      var baseRoomPrice = 0;
      if (
        form?.rateCeiling && Number(form?.rateCeiling) !== 0 &&
        Number(form?.baseRoomTypeNewPrice) > Number(form?.rateCeiling) &&
        data?.baseRoomTypeRate > Number(form?.rateCeiling)
      ) {
        baseRoomPrice = form?.rateCeiling;
        //console.log("BRPRICE HERE FULL DATA5", baseRoomPrice);

      } else if (
        form?.rateFloor &&
        Number(form?.baseRoomTypeNewPrice) < Number(form?.rateFloor) && 
        data?.baseRoomTypeRate < Number(form?.rateFloor)
        
      ) {
        baseRoomPrice = form?.rateFloor;
        //console.log("Room Types HERE baseRoomPrice", form?.baseRoomTypeNewPrice, data?.baseRoomTypeRate,  form?.rateFloor);
        //console.log("BRPRICE HERE FULL DATA4", baseRoomPrice);

      } else {
        baseRoomPrice = form?.baseRoomTypeNewPrice
          ? (Number(form?.baseRoomTypeNewPrice) === 0) ? data?.baseRoomTypeRate : Number(form?.baseRoomTypeNewPrice )
          : data?.baseRoomTypeRate;
        //console.log("Room Types HERE baseRoomPrice ELSE", form?.baseRoomTypeNewPrice, data?.baseRoomTypeRate, (Number(form?.baseRoomTypeNewPrice) === 0), baseRoomPrice);
        //console.log("BRPRICE HERE FULL DATA3", baseRoomPrice);
      
      }
      //console.log("BRPRICE HERE FULL DATA1", baseRoomPrice);
      var brprice = baseRoomPrice;
      if (form?.rateCeiling && Number(form?.rateCeiling) !== 0) {
        brprice = Math.round(
          Math.min(Math.max(baseRoomPrice, form?.rateFloor), form?.rateCeiling),
        );
      } else {
        brprice = Math.round(Math.max(baseRoomPrice, form?.rateFloor));
      }
      console.log("BRPRICE HERE FULL DATA2", brprice);
      const baseRoom = {
        roomTypeId: data?.baseRoomTypeId,
        price: brprice,
      };
      const roomTypes = form?.roomTypes.map((room) => {
        // console.log(
        //   "STATS",
        //   form?.rateCeiling,
        //   form?.rateFloor,
        //   Number(room.newPrice),
        //   Number(form?.baseRoomTypeNewPrice)
        // );
        if (
          form?.rateCeiling &&
          Number(form?.rateCeiling) < Number(form?.baseRoomTypeNewPrice) &&
          data?.baseRoomTypeRate > Number(form?.rateCeiling)
        ) {
          return {
            roomTypeId: room.id,
            price: String(form?.relativePrice ? 
              Number(form?.rateCeiling) +
              (Number(form?.roomTypes.find(r => r.name === room.name)?.percentage) / 100) * Number(form?.rateCeiling) :
              Number(form?.rateCeiling) +
              (Number(form?.roomTypes.find(r => r.name === room.name)?.fixed))),
          };
        } else if (
          form?.rateFloor &&
          Number(form?.rateFloor) > Number(form?.baseRoomTypeNewPrice) &&
          data?.baseRoomTypeRate < Number(form?.rateFloor)
        ) {
          // console.log(
          //   "WE ARE IN FLOOR",
          //   form?.rateFloor,
          //   Number(form?.baseRoomTypeNewPrice)
          // );
          return {
            roomTypeId: room.id,
            price: String(form?.relativePrice ? 
              Number(form?.rateFloor) +
              (Number(form?.roomTypes.find(r => r.name === room.name)?.percentage) / 100) * Number(form?.rateFloor) :
              Number(form?.rateFloor) +
              (Number(form?.roomTypes.find(r => r.name === room.name)?.fixed))),
          };
        } else {
         //console.log("WE PASSED FLOOR");
          return {
            roomTypeId: room.id,
            price: room.newPrice ? room.newPrice : room.rate,
          };
        }
      });
      console.log("NEW ROOM ROOM TYPES", form);
      const NewroomTypes = form?.roomTypes.map((room) => {
        // console.log(
        //   "STATS",
        //   form?.rateCeiling,
        //   form?.rateFloor,
        //   Number(room.newPrice),
        //   Number(form?.baseRoomTypeNewPrice)
        // );
        if (
          form?.rateCeiling && form?.rateCeiling !== "0" &&
          Number(form?.rateCeiling) < Number(form?.baseRoomTypeNewPrice) &&
          data?.baseRoomTypeRate > Number(form?.rateCeiling)
        ) {
          return {
            ...room,
            roomTypeId: room.id,
            price: String(form?.relativePrice ? 
              Number(form?.rateCeiling) +
              (Number(form?.roomTypes.find(r => r.name === room.name)?.percentage) / 100) * Number(form?.rateCeiling) :
              Number(form?.rateCeiling) +
              (Number(form?.roomTypes.find(r => r.name === room.name)?.fixed))),
            newPrice: "0", // String(Number(form?.rateCeiling) + ((Number(room.percentage) / 100) * Number(form?.rateCeiling))),
            rate: String(form?.relativePrice ? 
              Number(form?.rateCeiling) +
              (Number(form?.roomTypes.find(r => r.name === room.name)?.percentage) / 100) * Number(form?.rateCeiling) :
              Number(form?.rateCeiling) +
              (Number(form?.roomTypes.find(r => r.name === room.name)?.fixed))),
          };
        } else if (
          form?.rateFloor &&
          Number(form?.rateFloor) > Number(form?.baseRoomTypeNewPrice) &&
          data?.baseRoomTypeRate < Number(form?.rateFloor)
        ) {
          // console.log(
          //   "WE ARE IN FLOOR",
          //   form?.rateFloor,
          //   Number(form?.baseRoomTypeNewPrice)
          // );
          return {
            ...room,
            roomTypeId: room.id,
            price: String(form?.relativePrice ? 
              Number(form?.rateFloor) +
              (Number(form?.roomTypes.find(r => r.name === room.name)?.percentage) / 100) * Number(form?.rateFloor) :
              Number(form?.rateFloor) +
              (Number(form?.roomTypes.find(r => r.name === room.name)?.fixed))),
            newPrice: "0", //String(Number(form?.rateFloor) + ((Number(room.percentage) / 100) * Number(form?.rateFloor))),
            rate: String(form?.relativePrice ? 
              Number(form?.rateFloor) +
              (Number(form?.roomTypes.find(r => r.name === room.name)?.percentage) / 100) * Number(form?.rateFloor) :
              Number(form?.rateFloor) +
              (Number(form?.roomTypes.find(r => r.name === room.name)?.fixed))),
          };
        } else {
          console.log("WE ARE IN ELSE 123", form);
          return {
            ...room,
            roomTypeId: room.id,
            price: form?.relativePrice ? (room.newPrice ? room.newPrice : room.rate)  : (form?.baseRoomTypeNewPrice + (form?.roomTypes.find(r => r.name === room.name)?.fixed)),
            newPrice: "0",
            rate: form?.relativePrice ?(Number(room.newPrice)
              ? Number(room.newPrice)
              : Number(room.rate)): Number(form?.baseRoomTypeNewPrice + (form?.roomTypes.find(r => r.name === room.name)?.fixed)),
          };
        }
      });
      console.log("Room Types HERE", baseRoom, roomTypes);
      console.log("Base Room Rate HERE", data?.baseRoomTypeRate, baseRoom.price, data);
      const types = [baseRoom, ...roomTypes];
      const fixedByRoom = {}; 
      const percentagesByRoom = {};
      form?.roomTypes.forEach((room) => {
        fixedByRoom[room.id] = room.fixed;
        percentagesByRoom[room.id] = room.percentage;
      });
      
      
      console.log("Checking FIXED AND RELATIVE PRICES 15", NewroomTypes, roomTypes, baseRoom);
      const altRoomsOverride = form?.roomTypes.some(room => {
        const dataRoom = data?.roomTypes?.find(r => r.name === room.name);
        return room.fixed !== dataRoom?.fixed || room.percentage !== dataRoom?.percentage;
      });
    
      const isOverride = baseRoom.price !== data?.baseRoomTypeRate || data.isOverride || altRoomsOverride;
      const payload: any = {
        startDate: form?.startDate,
        roomTypePrices: types,
        rateFloor: form?.rateFloor,
        rateCeiling: form?.rateCeiling,
        isOverride: isOverride,
        fixedByRoom: fixedByRoom,
        percentagesByRoom: percentagesByRoom,
        overridedByFixed: isOverride && !form?.relativePrice ? true : false
      };
      if (form?.bulk) {
        payload.startDate = form?.startDate;
        payload.endDate = form?.endDate;
      }
      console.log("WE ARE IN PRICE ADJUSTMENT MODAL", payload);
      const response = await api.saveDayPrice(user?.id, payload);
      console.log("saving roomtypes", NewroomTypes, fixedByRoom, percentagesByRoom);
      setForm((prev) => ({
        ...prev,
        baseRoomTypeNewPrice: "",
        roomTypes: NewroomTypes,
      }));
      console.log("Adjusted form", form.roomTypes);

      onUpdate();

      setSaving(false);
    } catch (e: any) {
      setSaving(false);
      console.log("error", e?.response?.data?.message);
    }
  };
  console.log("form", form, data);
  return (
    <SideModal
      className="!z-[9] lg:!z-[4]"
      onClose={handleClose}
    >
      <div className="h-full min-w-[440px] lg:w-[480px] w-full relative bg-[#f5f2f0] flex flex-col p-6 font-[EZ-Allianz]">

        <div className="flex justify-between items-start mb-8 border-b border-[#000] pb-4">
          <p className="text-black text-[14px] lg:text-[18px] tall:lg:text-[18px] tall:xl:text-[20px] font-semibold">Price Settings</p>
          <div className="flex items-center gap-2">
            <p className="text-[12px]">
              {moment(selectedDate).format("MMM DD, YYYY")}
            </p>
            <button
              onClick={handleClose}
              className=""
            >
              <CloseIcon className="w-4 h-4" />
            </button>
          </div>
        </div>

        <div className="flex-1 overflow-y-auto">
          <h2 className="text-[16px] font-semibold mb-4">Settings</h2>
          <div className="bg-white rounded-[8px] p-6 mb-8">
            <div className="grid grid-cols-4 gap-4 pb-4 mb-4 border-b border-[#000]">
              <div className="flex items-center gap-1">
                <p className="text-[12px] font-semibold">Constraint</p>
                <div title={"These constraints apply to your base room rate. All other room categories will adjust proportionally based on your base room rate."}>
                  <InfoIcon className="w-4 h-4 text-neutral-500" />
                </div>
              </div>
              
              <div></div>
              <p className="text-[12px] text-center font-semibold">Current</p>
              <p className="text-[12px] text-center font-semibold">New</p>
            </div>
            <div className="grid grid-cols-4 gap-4">
              <p className="text-[12px]">Rate Floor</p>
              <div></div>
              <div className="flex items-center justify-center">
                <p className="text-[12px] font-sans">${form?.rateFloor}</p>
              </div>
              <div className="flex justify-center">
                <PriceInput
                  onChange={(value) => {
                    setForm((prev) => ({
                      ...prev,
                      rateFloor: value,
                    }));
                  }}
                  value={form?.rateFloor}
                  className="font-sans bg-[#F6ECE4] border-[#F5EAE1] text-center"
                />
              </div>

              <p className="text-[12px]">Rate Ceiling</p>
              <div></div>
              <div className="flex items-center justify-center">
                <p className="text-[12px] font-sans">${form?.rateCeiling}</p>
              </div>
              <div className="flex justify-center">
                <PriceInput
                  onChange={(value) => {
                    setForm((prev) => ({
                      ...prev,
                      
                      rateCeiling: value,
                    }));
                  }}
                  value={form?.rateCeiling}
                  className="font-sans bg-[#F6ECE4] border-[#F5EAE1] text-center"
                />
              </div>
            </div>
          </div>

          <h2 className="text-[16px] font-semibold mb-4">Price Override</h2>
          <div className="bg-white rounded-[8px] p-6">
            <div className="py-[7px] border-b border-[#000] justify-between items-center flex mb-3">
              <div className="text-right text-[#000] text-2xs font-medium leading-[11px]">
                Bulk Price Edit?
              </div>
              <div className="justify-start items-center gap-3 flex">
                <div className="text-right text-[#000] text-2xs font-medium leading-[11px]">
                  Single day
                </div>
                <Switch
                  checked={form?.bulk}
                  onChange={() =>
                    setForm((prev) => ({
                      ...prev,
                      bulk: !prev.bulk,
                      startDate: moment(selectedDate).format("YYYY-MM-DD"),
                      endDate: moment(selectedDate).endOf("month").format("YYYY-MM-DD"),
                    }))
                  }
                />
                <div className="text-right text-[#000] text-2xs font-medium leading-[11px]">
                  Bulk
                </div>
                <div title={"Select \"Single Day\" to override the price for just one specific day. Use the \"Bulk\" option to set pricing rules for a range of dates."}>
                    <InfoIcon className="w-4 h-4 text-neutral-500" />
              </div>
              </div>
              <div className="text-right flex items-center gap-1.5 text-[#646466] text-2xs font-medium leading-[11px]">
                <DatePicker
                  showIcon={false}
                  icon={null}
                  toggleCalendarOnIconClick
                  selected={form.startDate ? getDate(form.startDate) : null}
                  onChange={(date) => {
                    setForm((prev) => ({
                      ...prev,
                      startDate: moment(date).format("YYYY-MM-DD"),
                      endDate: form.bulk 
                        ? moment(date).endOf("month").format("YYYY-MM-DD")
                        : moment(date).format("YYYY-MM-DD"),
                    }));
                  }}
                  dateFormat="MMM d"
                  className="!outline-none p-0 text-2xs font-medium border-0 max-w-10 !h-3"
                  popperPlacement="bottom-start"
                />
                {form?.bulk && (
                  <>
                    -
                    <DatePicker
                      showIcon={false}
                      icon={null}
                      toggleCalendarOnIconClick
                      selected={form.endDate ? getDate(form.endDate) : null}
                      onChange={(date) => {
                        setForm((prev) => ({
                          ...prev,
                          endDate: moment(date).format("YYYY-MM-DD"),
                        }));
                      }}
                      dateFormat="MMM d"
                      className="!outline-none p-0 text-2xs font-medium border-0 max-w-10 !h-3"
                      popperPlacement="bottom-start"
                    />
                  </>
                )}
              </div>
            </div>

            <div className="grid grid-cols-4 gap-4 mb-4">
              <p className="text-[12px] font-semibold">Room Category</p>
              <p className="text-[12px] text-center font-semibold">
                
                  <span className="text-[12px] text-center font-semibold flex items-center justify-center gap-1">
                    <span className="whitespace-nowrap">{form?.relativePrice ? "Relative" : "Fixed"} Price</span>
                    <span title={form?.relativePrice ? "Represents the percentage premium or discount applied to your base room price." : "Represents the exact dollar amount premium or discount applied to your base room price."}>
                      <InfoIcon className="w-4 h-4 text-neutral-500" />
                    </span>
                  </span>
             </p>
              <p className="text-[12px] text-center font-semibold">Current Price</p>
              <p className="text-[12px] text-center font-semibold">New Price</p>
            </div>

            <div className="grid grid-cols-4 gap-4 py-2 border-t border-[#000] items-center">
              <p className="text-[12px]">{data?.baseRoomTypeName} (Base Price)</p>
              <p className="text-[12px]">{""}</p>
              <div className="flex items-center justify-center">
                <p className="text-[12px] font-sans">${data?.baseRoomTypeRate.toFixed(0)}</p>
              </div>
              <div className="flex justify-center">
                <PriceInput
                  onChange={(value) => {
                    setForm((prev) => ({
                      ...prev,
                      relativePrice: form?.relativePrice,
                      baseRoomTypeNewPrice: value || "",
                      roomTypes: form?.roomTypes?.map((room) => ({
                        ...room,
                        newPrice: form?.relativePrice ? calculateNewPrice(
                          form?.relativePrice
                            ? room?.percentage
                            : room?.newRelativePrice,
                          value,
                          form?.relativePrice
                        ) : String(Number(value) + Number(room?.fixed)),
                      })),
                    }));
                  }}
                  value={form?.baseRoomTypeNewPrice}
                  className="bg-[#F6ECE4] border-[#F5EAE1] text-[#000] font-sans text-center"
                />
              </div>
            </div>

            {data?.roomTypes?.map((room) => ( 
              <div key={room.name} className="grid grid-cols-4 gap-4 py-2 border-t border-gray-100 items-center">
                <p className="text-[12px]">{room.name}</p>
                <div className="flex justify-center">
                  <PriceInput
                    isPercentage={form?.relativePrice}
                    onChange={(value) => {
                      setForm((prev) => ({
                        ...prev,
                        relativePrice: form?.relativePrice,
                        roomTypes: prev.roomTypes.map((r) =>
                          r.name === room.name
                            ? {
                              ...r,
                              percentage: form?.relativePrice ? String(Math.abs(Number(value))) : room.percentage,
                              fixed: form?.relativePrice ? room.fixed : String(Math.abs(Number(value))),
                              newPrice: form?.relativePrice ? String( Number(Number(Number(String(Math.abs(Number(value))))/100 * Number(form?.baseRoomTypeNewPrice  === "" || form?.baseRoomTypeNewPrice  === "0" || form?.baseRoomTypeNewPrice  === null ? form?.baseRoomTypeRate : form?.baseRoomTypeNewPrice)).toFixed(0)) + Number(form?.baseRoomTypeNewPrice  === "" || form?.baseRoomTypeNewPrice  === "0" || form?.baseRoomTypeNewPrice  === null ? form?.baseRoomTypeRate : form?.baseRoomTypeNewPrice))
                               : String(Number(String(Math.abs(Number(value)))) + Number(form?.baseRoomTypeNewPrice  === "" || form?.baseRoomTypeNewPrice  === "0" || form?.baseRoomTypeNewPrice  === null ? form?.baseRoomTypeRate : form?.baseRoomTypeNewPrice)),
                              // newPrice:form?.relativePrice ? calculateNewPrice(
                              //   value,
                              //   form.baseRoomTypeNewPrice,
                              //   form?.relativePrice
                              // ) : form.baseRoomTypeNewPrice + value,
                              //newFixedPrice: value + room.fixed || "",
                              //fixed: form?.relativePrice ? room.fixed : value
                              
                            }
                            : r
                        ),
                      }));
                    }}
                    value={form?.relativePrice ? `${form.roomTypes.find(r => r.name === room.name)?.percentage || ""}` : `${form.roomTypes.find(r => r.name === room.name)?.fixed || ""}`}
                    className="bg-white font-sans text-center"
                  />
                </div>
                <div className="flex items-center justify-center">
                  <p className="text-[12px] font-sans">${  commaSeparatedNumber(room.rate || 0, false,false)}</p>
                </div>
                <div className="flex justify-center">
                  <PriceInput
                    onChange={(value) => {
                      setForm((prev) => {
                        let roomTypes = []
                        if (form?.relativePrice) {
                            roomTypes = prev.roomTypes.map((r) => {
                              console.log("Checking Room Types", r, room, value);
                              if (r.name === room.name) {
                              return {
                                ...r,
                                newPrice: value || "",
                              };
                            }
                            return r;
                          });
                        }
                        else {
                          roomTypes = prev.roomTypes.map((r) => {
                            if (r.name === room.name) {
                              return {
                                ...r,
                                newPrice: value || "",
                              };
                            }
                            return r;
                          });
                        }
                        return {
                          ...prev,
                          relativePrice: form?.relativePrice,
                          roomTypes,
                        };
                      });
                    }}
                    value={form?.relativePrice ? form?.roomTypes.find(r => r.name === room.name)?.newPrice : form?.roomTypes.find(r => r.name === room.name)?.newPrice}
                    className="font-sans text-center"
                    disabled={true}
                  />
                </div>
              </div>
            ))}
            {/* {data?.roomTypes?.length > 5 && (
              <p className="text-[12px] text-gray-500 mt-2 italic">
                Showing 5 out of {data?.roomTypes?.length} room types
              </p>
            )} */}

            <div className="flex items-center gap-4 mt-6 mb-2 border-t border-b border-black py-4">
              <p className="text-[12px]">Change by?</p>
              <div className="flex items-center gap-2">
                <p className="text-[12px]">Relative Price</p>
                <Switch
                  checked={!form?.relativePrice}
                  onChange={() => {
                    if (!form?.relativePrice) {
                      const price =
                        form?.baseRoomTypeNewPrice === "" || form?.baseRoomTypeNewPrice === "0" || form?.baseRoomTypeNewPrice === null ? `${form?.baseRoomTypeRate}` : form?.baseRoomTypeNewPrice;
                      console.log("SWITCH FORM HERE", form, data, price);
                      setForm((prev) => ({
                        ...prev,
                        
                        baseRoomTypeNewPrice: price,
                        relativePrice: !prev.relativePrice,
                        roomTypes:  form?.roomTypes?.map((room) => ({
                          ...room,
                          newRelativePrice: "",
                          newPrice: (form?.baseRoomTypeNewPrice && form?.baseRoomTypeNewPrice !== "0") ? form?.relativePrice ? `${(
                            Number(price || 0) +
                            (Number(room?.percentage) / 100) * Number(price || 0)
                          ).toFixed(0)}` : `${(
                            Number(price || 0) +
                            (Number(room?.fixed)) 
                          ).toFixed(0)}` : "0",
                        })) ,
                      }));
                    } else {
                      const price =
                        form?.baseRoomTypeNewPrice === "" || form?.baseRoomTypeNewPrice === "0" || form?.baseRoomTypeNewPrice === null ? `${form?.baseRoomTypeRate}` : form?.baseRoomTypeNewPrice;
                      setForm((prev) => ({
                        ...prev,
                        relativePrice: !prev.relativePrice,
                        roomTypes: form?.roomTypes?.map((room) => {
                          const originalData = data?.roomTypes?.find(
                            (r) => r.name === room.name
                          );
                          return {
                            ...room,
                            percentage: originalData?.percentage || 0,
                            newPrice: (form?.baseRoomTypeNewPrice && form?.baseRoomTypeNewPrice !== "0") ? !form?.relativePrice ? `${(
                              Number(price || 0) +
                              (Number(room?.percentage) / 100) * Number(price || 0)
                            ).toFixed(0)}` : `${(
                              Number(price || 0) +
                              (Number(room?.fixed)) 
                            ).toFixed(0)}` : "0",
                          };
                        }),
                      }));
                    }
                  }}
                />
                <p className="text-[12px]">Fixed Price</p>
                <div title={"Select Relative Price to adjust each room category price as a percentage difference from your base room rate. Select Fixed Price to adjust each room category price by a fixed dollar amount. Using Relative Price is recommended for dynamic adjustments."}>
              <InfoIcon className="w-4 h-4 text-neutral-500" />
        </div>
              </div>
            </div>

            <p className="text-[12px] italic">
              Note: Over-riding recommended prices will disable dynamic pricing
            </p>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4 mt-6">
          <Button
            variant="outline"
            className="w-full"
            disabled={saving}
            onClick={() => setShowConfirmation(true)}
          >
            Reset All
          </Button>
          <Button
            className="w-full bg-[#0B252A] hover:bg-[#0B252A]/90"
            onClick={handleSave}
            loading={saving}
          >
            Save
          </Button>
        </div>
      </div>

      {showConfirmation && (
        <ConfirmResetModal
          onClose={async () => {
            setShowConfirmation(false);
           
          

            handleReset();
           
            // await handleReset();
            // onUpdate();
          }}
          userId={user?.id}
          refetch={onUpdate}
          dates={
            form?.bulk
              ? {
                startDate: moment(form?.startDate).format("YYYY-MM-DD"),
                endDate: moment(form?.endDate).format("YYYY-MM-DD"),
              }
              : {
                startDate: selectedDate || "",
                endDate: selectedDate || "",
              }
          }
        />
      )}
    </SideModal>
  );
}