import { useEffect, useMemo, useRef, useState } from "react";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  LineChart,
  Line,
  BarChart,
  Bar,
  Rectangle,
  Label,
  LabelList,
} from "recharts";
import { Panel } from "../../../../components/Panel";
import moment from "moment";
import { FilterSelect, types } from "./components/FilterSelect";
import { YearFilter } from "../YearFilter";
import { api } from "../../../../helpers/api";
import {
  commaSeparatedNumber,
  formatNumber,
  getHeightInVh,
  getUser,
} from "../../../../helpers/utils";
import {
  transformCompetitorPrices,
  transformData,
  transformDataReviews,
  transformRevenueByChannelData,
} from "./utils";
import useResponsive from "../../../../helpers/useResponsive";
import Spinner from "../../../../components/Spinners";
import { truncate } from "fs";

const numberFilters = ["rooms_ooo", "booking_window", "guest_reviews"];
const percentageFilters = ["revenue_by_channel"];

const channelColors = {
  direct: "#2F3D37",
  bookingDotCom: "#000",
  expedia: "#b7d3ff",
  other: "#E5E7EB",
};
const channelLabels = {
  bookingDotCom: "Booking.com",
  direct: "Direct",
  expedia: "Expedia",
  other: "Other",
};

const COLORS = [  
  "#E0DAE5",

  "#E8CCBF",

  "#d7dedf",
  "#e2e8e9",
  "#d7dedf",
  "#ccd4d5",
  "#c1cacc",
  "#d4ceda",
  "#c8c1cf",
  "#bbb3c4",
  "#aea5b9"


  ]
const titles = {
  expedia: "Expedia",
  booking_com: "Booking.com",
  makemytrip: "MakeMyTrip",
  tripadvisor: "Tripadvisor",
  google: "Google",
  trivago: "Trivago",
  agoda: "Agoda",
  hotels_com: "Hotels.com",
};

export const getDates = (year?: string | number) => {
  const currentYear = year ? Number(year) : new Date().getFullYear();
  const endMonth = year === "next3" ? 3 : 12;
  if (year && ["next12", "next3"].includes(year as string)) {
    const today = moment();
    const startDate = today.clone().add(1, "day"); // Next day after the end of the current month
    let endDate;

    if (year === "next3") {
      endDate = startDate.clone().add(3, "months").subtract(1, "day"); // 3 months from start, minus 1 day
    } else if (year === "next12") {
      endDate = startDate.clone().add(12, "months").subtract(1, "day"); // 12 months from start, minus 1 day
    } else {
      throw new Error('Invalid period type. Use "next3" or "next12".');
    }

    return {
      startDate: startDate.format("YYYY-MM-DD"),
      endDate: endDate.format("YYYY-MM-DD"),
      year: year,
    };
  }
  const startDate = moment()
    .set("year", currentYear)
    .startOf("year")
    .format("YYYY-MM-DD");
  const endDate = moment()
    .set("year", currentYear)
    .endOf("year")
    .format("YYYY-MM-DD");
  return {
    startDate,
    endDate,
    year: `${currentYear}`,
  };
};

const CustomCursor = (props) => {
  const { x, y, width, height } = props;
  // make width 25px
  const newWidth = 25;
  // set x in center
  const remaining = (width - (newWidth + 1)) / 2;
  return (
    <Rectangle
      fill="url(#gradient)"
      x={x + remaining}
      y={y - 5}
      width={newWidth}
      height={height + 5}
      radius={[6, 6, 0, 0]}
    />
  );
};

const CustomBarTooltip = () => {
  return null;
};

const CustomTooltip = ({
  active,
  payload,
  label,
  field,
  data: rawData,
  competitorInfo,
}: any) => {
  const data = payload?.[0]?.payload;
  const isBookingWindow = field === "booking_window";
  const isPercentage = percentageFilters.includes(field) || false;
  const isNumber = numberFilters.includes(field) || false;

  const competitorRates = rawData?.competitorRates || [];
  //console.log("Custom Tooltip", data);
  return (
    <div className="bg-white rounded-[8px] shadow-[0px_8px_28px_0px_rgba(20,20,43,0.10)] border border-[#F0F0F0]" style={{position: 'relative', zIndex: 9999}}>
      {field === "revenue_by_channel" ? (
        <>
          <div className="text-xs text-muted-foreground mb-3 text-center bg-[#F4F4F4] p-2">
            {data?.name}
          </div>
          <div className="space-y-2 p-4">
            {Object.keys(channelColors).map((key) => (
              <div key={key} className="flex items-center gap-2">
                <div
                  className="w-2 h-2 rounded-full"
                  style={{ backgroundColor: channelColors[key] }}
                />
                <span className="text-xs">{channelLabels[key]}</span>
                <span className="text-xs">
                  {commaSeparatedNumber(data?.[key], true)}
                </span>
              </div>
            ))}
          </div>
        </>
      ) : field === "competitor_prices" ? (
        <>
          <div className="text-xs text-muted-foreground mb-3 text-center bg-[#F4F4F4] p-2">
            {moment(data?.name).format("MMM DD, YYYY")}
          </div>
          <div className="space-y-2 pt-2 pb-4 pl-4 pr-4">
            {Object.keys(competitorInfo).map((key) => (
              <div
                key={key}
                className="flex items-center justify-between gap-2"
              >
                <div className="flex items-center gap-2">
                  <div
                    className="w-2 h-2 rounded-full"
                    style={{ backgroundColor: competitorInfo[key]?.color }}
                  />
                  <span className="text-xs">{competitorInfo[key]?.label}</span>
                </div>
                <span className="text-xs text-[#000]">
                  {commaSeparatedNumber(data?.[key], false)}
                </span>
              </div>
            ))}
          </div>
        </>
      ) : (
        <>
          <div className="text-xs text-muted-foreground mb-3 text-center bg-[#F4F4F4] p-2">
            {data?.name}
          </div>
          <div className="space-y-2 pt-2 pb-4 pl-4 pr-4">
            <div className="flex items-center gap-2">
              <div className="w-2 h-2 bg-black rounded-full" />
              <span className="text-xs">
                {types.find((e) => e.value === field)?.label || field}
              </span>
              <div className="self-stretch text-black text-[13px] font-semibold font-['Inter']">
                {field === "guest_reviews"
                  ? Number(data?.count.toFixed(2)).toString()
                  : commaSeparatedNumber(data?.count, isPercentage, !isNumber)}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export function TrendsLineChart() {
  const user = getUser();
  const [isLoading, setIsLoading] = useState(false);
  const competitorInfo = useRef<any>({});
  const { isTall2XLDesktop, is1XLDesktop, isQHD, isTall2, isMobile } =
    useResponsive();
  const [data, setData] = useState<any>({});
  const [filter, setFilter] = useState<any>({
    ...getDates("next12"),
    type: "competitor_prices",
  });

  const fetchMetrics = async () => {
    try {
      setIsLoading(true);
      const userId = user?.id;
      const filters = {
        type: "Trends",
        startDate: filter.startDate,
        endDate: filter.endDate,
      };
      const metrics = await api.getMetrics(userId, filters);
     //console.log("FETCH METRICS", metrics);
      setData(metrics);
    } catch (error) {
     //console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    fetchMetrics();
  }, [filter]);

  const graph = useMemo(() => {
    let transformedData: any[] = [];
    if (filter.type === "competitor_prices") {
     //console.log("Competitor Prices Data", data);
      const { data: newData, metaInfo } = transformCompetitorPrices({
        data: data?.competitorRates || [],
        hotelRates: data?.hotelRates || [],
      });
      competitorInfo.current = metaInfo;
      transformedData = newData;
    } else if (filter.type === "occ_index") {
      transformedData = transformData({
        data: data?.totalOccupancyByMonth || [],
        currentField: "occupancy",
      });
    } else if (filter.type === "ravpar_index") {
      transformedData = transformData({
        data: data?.totalRevparByMonth || [],
        currentField: "revpar",
      });
    } else if (filter.type === "booking_window") {
      transformedData = transformData({
        data: data?.totalAvgLengthOfStayByMonth || [],
        currentField: "count",
      });
    } else if (filter.type === "rooms_ooo") {
      transformedData = transformData({
        data: data?.totalUnAvailableRoomsByMonth || [],
        currentField: "count",
      });
    } else if (filter.type === "revenue_by_channel") {
      transformedData = transformRevenueByChannelData({
        data: data?.revenueByChannel || {},
      });
    } else if (filter.type === "guest_reviews") {
     //console.log("Guest Reviews Data TRANSFORMEING", data);
      // transformedData = transformDataReviews({
      //   data: data?.reviews || [],
      //   currentField: "average",
      // });
      transformedData = data?.reviews?.map((e) => ({
        name: e?.websitename,
        sum: e?.averagescore,
      }));
     //console.log("Guest Reviews Data TRANSFORMED", transformedData);
    }
    return transformedData;
  }, [data, filter]);
  //console.log("TRANSFORMED HEREHEREHE DATA", graph);
  return (
    <Panel
      label="Trends"
      actions={
        <div className="flex items-center gap-1.5">
          <FilterSelect filter={filter} setFilter={setFilter} />
          <YearFilter
            filter={filter}
            disabled={false}
            onChange={(type: any) => {
              setFilter({ ...filter, ...getDates(type), year: type });
            }}
            isCompetitorPrice={filter.type === "competitor_prices"}
          />
        </div>
      }
      infoTooltip={filter.type === "competitor_prices" ? 
        "This chart displays the lowest rate of your competitor hotels across time. If no value was able to be scraped it shows up as 0." 
        : filter.type === "booking_window" ?
        "This chart shows the average days before arrival reservations are made. A shorter booking window indicates you can expect more last minute bookings."
        : filter.type === "guest_reviews" ?
        "Google reviews have been adjusted to a 1-10 scale, as Google measures on a 1-5 scale."
        : filter.type === "revenue_by_channel" ?
        "For future dates this chart shows the segmentation \"on the books\" as of today."
        : ""}
      className="h-[310px] tall3:h-[360px] tall3XL:h-[410px] qhd1:h-[600px] flex flex-col tall3XL:[&_.header]:h-[50px] "
      contentClassName="h-[calc(100%-45px)] tall3XL:h-[calc(100%-50px)]"
      style={{
        position: 'relative',
        zIndex: 1
      }}
    >
      {isLoading ? (
        <div className="flex justify-center items-center h-full">
          <Spinner className="!text-primary" />
        </div>
      ) : (
        <div
          className={`lg:w-[218px] ${
            filter.type === "competitor_prices" && graph.length !== 0
              ? "competitor_prices"
              : "relative"
          } xl:w-full overflow-visible md:overflow-visible pt-6 pb-0 pl-4 flex justify-center items-center h-full`}
        >
          {graph.length === 0 && (
            <div className="absolute top-0 text-neutral-700 text-sm font-medium left-0 flex justify-center text-center flex-1 items-center w-full h-full">
              No data available
            </div>
          )}
          <ResponsiveContainer
            height={isMobile ? 225 : undefined}
            // height={
            //   isQHD
            //     ? 500
            //     : isTall2 || isMobile
            //     ? 209
            //     : isTall2XLDesktop
            //     ? 275
            //     : 300
            // }
          >
            {filter.type === "guest_reviews" ? (
              <BarChart
                // width={500}
                // height={300}
                data={graph}
                margin={{
                  top: 5,
                  right: 20,
                  left: 5,
                  bottom: 35,
                }}
                barGap={4}
              >
                <CartesianGrid
                  strokeDasharray="0"
                  vertical={false}
                  horizontal={{
                    color: "red",
                  }}
                />
                <XAxis
                  dataKey="name"
                  axisLine={false}
                  tickLine={false}
                  className="!text-3xs !text-neutral-600 leading-[11px]"
                  tickFormatter={(value) => {
                    return titles[value] || value;
                  }}
                  interval={0}
                  minTickGap={-200}
                  angle={45}
                  dx={15}
                  dy={20}
                ></XAxis>
                <YAxis
                  axisLine={false}
                  tickLine={false}
                  width={30}
                  className="!text-2xs !text-neutral-600 leading-[11px]"
                  tickFormatter={(value) => {
                    const val = Number(value);
                    if (val === 0) {
                      return "0";
                    }
                    return commaSeparatedNumber(val, false, false);
                  }}
                />
                <Tooltip
                  position={{ y: 0 }}
                  content={<CustomBarTooltip />}
                  cursor={graph.length > 0 && <CustomCursor />}
                />
                <defs>
                  <linearGradient
                    id="gradient2"
                    x1="0%"
                    y1="0%"
                    x2="0%"
                    y2="100%"
                  >
                    <stop offset="0%" stopColor="#ACACAC" />
                    <stop offset="59%" stopColor="#5E5E5E" />
                    <stop offset="100%" stopColor="black" />
                  </linearGradient>
                </defs>
                <Bar
                  dataKey="sum"
                  fill="url(#gradient2)"
                  barSize={18}
                  radius={[2, 2, 0, 0]}
                />
              </BarChart>
            ) : (
              <LineChart
                // width={500}
                height={isMobile ? 200 : undefined}
                data={graph}
                margin={{
                  top: 0,
                  right: 30,
                  left: numberFilters.includes(filter.type) ? 13 : 20,
                  bottom: 5,
                }}
                barGap={4}
              >
                <CartesianGrid
                  strokeDasharray="0"
                  vertical={false}
                  horizontal={{
                    color: "red",
                  }}
                />
                <XAxis
                  dataKey="name"
                  axisLine={false}
                  tickLine={false}
                  className="!text-2xs !text-neutral-600 leading-[11px]"
                  // ticks={["Jan", "Mar", "Jun", "Dec"]}
                  interval={"equidistantPreserveStart"}
                  tickFormatter={(value) => {
                    if (filter.type === "competitor_prices") {
                      const date = moment(value);

                      return date.isValid() ? date.format("MMM DD") : value;
                    }
                    return value;
                  }}
                />
                <YAxis
                  axisLine={false}
                  tickLine={false}
                  className="!text-2xs !text-neutral-600 leading-[11px]"
                  width={20}
                  tickFormatter={(value) => {
                    const val = Number(value);
                    if (filter.type === "competitor_prices") {
                      return commaSeparatedNumber(val, false, false);
                    }

                    const sign = percentageFilters.includes(filter.type)
                      ? "%"
                      : numberFilters.includes(filter.type)
                      ? ""
                      : "$";
                    if (val === 0) {
                      return "0";
                    }

                    const formattedNumber = commaSeparatedNumber(
                      val,
                      false,
                      false
                    );
                    return sign === "%"
                      ? `${formattedNumber}${sign}`
                      : `${sign}${formattedNumber}`;
                  }}
                />
                <Tooltip
                  content={
                    <CustomTooltip
                      field={filter.type}
                      data={data}
                      competitorInfo={competitorInfo.current}
                    />
                  }
                  //
                  cursor={graph.length > 0 && <CustomCursor />}
                  
                  {...(filter.type === "competitor_prices" && {
                    allowEscapeViewBox: {
                      x: true,
                      y: true,
                    },
                    offset: 1,
                    wrapperStyle: {

                      zIndex: 9999, // Controls the stacking order of the tooltip - higher values appear on top of elements with lower z-index

                    },
                  })}
                />
                <defs>
                  <linearGradient
                    id="gradient"
                    x1="0%"
                    y1="0%"
                    x2="0%"
                    y2="100%"
                  >
                    <stop offset="0%" stop-color="rgba(204, 196, 255, 1)" />
                    <stop offset="100%" stop-color="white" />
                  </linearGradient>
                </defs>
                {filter.type === "competitor_prices" && (
                  <>
                    {graph[0] &&
                      Object?.keys(graph[0] || {})
                        .filter((key) => key !== "name")
                        .map((key) => (
                          <Line
                            key={key}
                            type="monotone"
                            dot={false}
                            strokeWidth={2}
                            dataKey={key}
                            stroke={competitorInfo.current[key]?.color}
                          />
                        ))}
                  </>
                )}

                {filter.type === "revenue_by_channel" && (
                  <>
                    <Line
                      type="monotone"
                      dot={false}
                      strokeWidth={2}
                      dataKey="bookingDotCom"
                      stroke="#000"
                    />
                    <Line
                      type="monotone"
                      dot={false}
                      strokeWidth={2}
                      dataKey="expedia"
                      stroke="#b7d3ff"
                    />
                    <Line
                      type="monotone"
                      dot={false}
                      strokeWidth={2}
                      dataKey="direct"
                      stroke="#2F3D37"
                    />
                    <Line
                      type="monotone"
                      dot={false}
                      strokeWidth={2}
                      dataKey="other"
                      stroke="#E5E7EB"
                    />
                  </>
                )}

                {filter.type === "guest_reviews" ? (
                  <Line
                    type="monotone"
                    dot={false}
                    strokeWidth={2}
                    dataKey="count"
                    stroke="#000"
                  >
                    <LabelList
                      dataKey="value"
                      position="top"
                      formatter={(value) => value.toFixed(2)}
                    />
                  </Line>
                ) : (
                  //filter.type !== "competitor_prices" &&
                  filter.type !== "revenue_by_channel" && (
                    <Line
                      type="monotone"
                      dot={false}
                      strokeWidth={2}
                      dataKey="count"
                      stroke="#000"
                    />
                  )
                )}
              </LineChart>
            )}
          </ResponsiveContainer>
        </div>
      )}
    </Panel>
  );
}
